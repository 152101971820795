import React from 'react';
import PageContentWrapper, { PageMetaData } from '../components/common/PageContentWrapper';
import { PATHS } from "../constants/paths"

interface ErrorBoundaryPageProps {
  error: Error;
}

const errorPageMetaData: PageMetaData = {
  title: 'CFUTDB | Error',
  description: "",
  href: PATHS.ERROR
}

const ErrorBoundaryPage: React.FC<ErrorBoundaryPageProps> = ({ error }) => {
  return (
    <PageContentWrapper pageMetaData={errorPageMetaData}>
      <div style={{ padding: '1rem', textAlign: 'center' }}>
        <h1>Something went wrong!</h1>
        <p>{error.message}</p>
      </div>
    </PageContentWrapper>
  );
};

export default ErrorBoundaryPage;
