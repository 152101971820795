import { Collapse, IconButton, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface CollapsiblePaperProps {
    title: string
    isInitiallyOpen: boolean
    children: React.ReactNode
}

export function CollapsiblePaper(props: CollapsiblePaperProps) {
    const {title, isInitiallyOpen, children} = props
    const [open, setOpen] = useState(isInitiallyOpen);
  
    const handleToggle = () => {
      setOpen(!open);
    };
  
    return (
      <Paper elevation={3} style={{ padding: 16 }} sx={{backgroundColor:'info.light'}}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" padding={1} color='common.white'>{title}</Typography>
          <IconButton onClick={handleToggle}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
        <Collapse in={open}>
          <div style={{ marginTop: 16 }}>
            {children}
          </div>
        </Collapse>
      </Paper>
    );
  };