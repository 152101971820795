import axios, { AxiosResponse } from "axios";
import { backendApi } from "../constants/apiConstants";
import { Position } from "../types/positionTypes";

export const getPositions = async (): Promise<Position[]> => {
    try {
      const response: AxiosResponse<Position[]> = await axios.get(backendApi+'/getPositions');
      console.log('reponse', response)
      return response.data;
    } catch (error) {
      console.error('Error fetching player data', error)
      throw error
    }
  }