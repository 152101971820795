import React from "react";
import { Position, PositionShortNames } from "../../../types/positionTypes";
import { PlayerFilters } from "../../../types/filters";
import { Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { getPositions } from "../../../services/getPositionData";
import { ZIndexValue } from "../../../constants/uiViewConstants";

interface FilterPlayerNameProps {
  selectedPositionShortNames: PositionShortNames[]
    setLocalFilters: React.Dispatch<React.SetStateAction<PlayerFilters>>
}
export function FilterPositions(props: FilterPlayerNameProps){
    const {selectedPositionShortNames, setLocalFilters} = props
    const {data: positions, isLoading, isError} = useQuery(['positions'], getPositions)
    
    const handlePositionChange = (position: Position) => {
      setLocalFilters((prevFilters) => {
        console.log("previously selected:",position)
        console.log("selected:",position)
        const isSelected = selectedPositionShortNames.some(positionShortName => positionShortName === position.positionShortName);
        const updatedSelectedPositionShortNames: PositionShortNames[] = isSelected
          ? selectedPositionShortNames.filter(positionShortName => positionShortName !== position.positionShortName)
          : [...selectedPositionShortNames, position.positionShortName]
        return { ...prevFilters, positionShortNames: updatedSelectedPositionShortNames }
      });
    };

    if(isLoading){
      return(
        (<p>Loading...</p>)
      )
    }

    if(isError || !positions){
      return(
        <p>No results found.</p>
      )
    }
    
    return (
      <div>
        <Typography variant="h6">Select Positions</Typography>
        <FormGroup>
          {positions.map((position) => (
            <FormControlLabel
              key={position.id}
              control={
                <Checkbox
                  checked={selectedPositionShortNames.some(positionShortName => positionShortName === position.positionShortName)}
                  onChange={() => handlePositionChange(position)}
                  name={position.positionName}
                />
              }
              label={position.positionName}
              sx={{zIndex: ZIndexValue.Popup}}
            />
          ))}
        </FormGroup>
      </div>
    );
}