import React from "react";
import { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoadingSpinner from "./components/common/LoadingSpinner";
import ErrorBoundaryPage from "./pages/ErrorBoundaryPage";
import HomePage from "./pages/HomePage";
import PlayerItemPage from "./pages/PlayerItemPage";
import PlayersPage from "./pages/PlayersPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import { PATHS } from "./constants/paths";

export function Router() {  
    const router = createBrowserRouter([
        {
            path: PATHS.HOME, 
            element: (
            <Suspense fallback={<LoadingSpinner/>}>
              <HomePage/>
            </Suspense>
          ),
          errorElement: <ErrorBoundaryPage error={new Error('Failed to load home page')} />,
        },
        {
          path: PATHS.PLAYERS, 
          element: (
            <Suspense fallback={<LoadingSpinner/>}>
              <PlayersPage/>
            </Suspense>
          ),
          errorElement: <ErrorBoundaryPage error={new Error('Failed to load players page')} />,
        },
        {
          path: `${PATHS.PLAYERS}/:playerItemId`, 
          element: (
            <Suspense fallback={<LoadingSpinner/>}>
              <PlayerItemPage/>
            </Suspense>
          ),
          errorElement: <ErrorBoundaryPage error={new Error('Failed to load player page')} />,
        },
        {
          path: PATHS.PRIVACY_POLICY, 
          element: (
            <Suspense fallback={<LoadingSpinner/>}>
              <PrivacyPolicyPage/>
            </Suspense>
          ),
          errorElement: <ErrorBoundaryPage error={new Error('Failed to load privacy policy')} />,
        },
      ]);
  
    return <RouterProvider router={router} />;
  }