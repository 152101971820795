import React from "react"
import PageContentWrapper, { PageMetaData } from "../components/common/PageContentWrapper"
import { Grid, Typography, useMediaQuery } from "@mui/material"
import { RecentPlayerList } from "../components/features/players/RecentPlayerList"
import theme from "../theme/theme"
import { CustomLink } from "../components/common/CustomLink"
import { PATHS } from "../constants/paths"

const homePageMetaData: PageMetaData = {
    title: 'CFUTDB | Home',
    description: "Discover College Football 25 Ultimate Team player stats, ratings, and rosters. Stay updated with the latest info on your favorite college athletes from EA Sports.",
    href: PATHS.HOME
}

function HomePage(){
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));  

    return(
        <PageContentWrapper showBreadcrumbs={false} pageMetaData={homePageMetaData}>
            <Grid container
                sx={{
                    paddingTop: 2,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
                spacing={isSm ? 0: 4}
            >
                <Grid item xs={12} sm={4} 
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant='h1' color='common.white'>
                        College Football Ultimate Team Database
                    </Typography>
                    <br></br>
                    <Typography variant='body1' color='common.white'>
                        Please note, we are still building this site so please bear with us... 
                        Watch CFUTDB progress into your one stop shop for all things College Football 25 Ultimate Team!
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={8}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant='h2' color='common.white'>Recently Added</Typography>
                    <RecentPlayerList/>
                    <br></br>
                    <CustomLink to='/players'>
                        <Typography color='common.white' variant='body1'>View all players</Typography>
                    </CustomLink>
                </Grid>
            </Grid>
        </PageContentWrapper>
    )
}

export default HomePage