import React from "react"
import PageContentWrapper, { PageMetaData } from "../components/common/PageContentWrapper"
import { PrivacyPolicyContent } from "../components/features/dataCollection/PrivacyPolicyContent"
import { PATHS } from "../constants/paths"

const privacyPolicyPageMetaData: PageMetaData = {
    title: 'CFUTDB | Privacy Policy',
    description: "",
    href: PATHS.PRIVACY_POLICY
}

function PrivacyPolicyPage(){
    return(
        <PageContentWrapper showBreadcrumbs={false} pageMetaData={privacyPolicyPageMetaData}>
            <PrivacyPolicyContent/>
        </PageContentWrapper>
    )
}

export default PrivacyPolicyPage