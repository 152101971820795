import React, { useEffect } from "react"
import { PlayerList } from "../components/features/players/PlayerList"
import PageContentWrapper, { PageMetaData } from "../components/common/PageContentWrapper"
import { FilterPlayersButton } from "../components/features/filterPlayers/FilterPlayersButton"
import { Box } from "@mui/material"
import { useQueryClient } from "react-query"
import { getTeams } from "../services/getTeamData"
import { getPositions } from "../services/getPositionData"
import { PATHS } from "../constants/paths"

const playersPageMetaData: PageMetaData = {
    title: 'CFUTDB | All Players',
    description: "Browse all College Football 25 Ultimate Team players. Filter by stats, position, or team to find top-rated athletes and enhance your game with EA Sports.",
    href: PATHS.PLAYERS
}

function PlayersPage(){
    const queryClient = useQueryClient()

    useEffect(() => {
        queryClient.prefetchQuery(['teams'], getTeams)
        queryClient.prefetchQuery(['positions'], getPositions)
      }, [queryClient]);

    return(
        <PageContentWrapper pageMetaData={playersPageMetaData}>
            <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
            }}
            >
                <FilterPlayersButton />
            </Box>
            <PlayerList />
        </PageContentWrapper>
    )
}

export default PlayersPage